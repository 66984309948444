import { contextRoot } from '@shared/env';

export default {
  getStore() {
    let promise;

    if (contextRoot === '@app') {
      promise = import('@app/config/store');
    } else {
      promise = import('@school/config/store');
    }

    return promise;
  },
};
