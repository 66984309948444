<script>
import { toBase64 } from '@shared/services/Utils';
import APIUpload from '@shared/services/API/Upload';

export default {
  i18n: {
    messages: {
      fr: {
        a_type: {
          image: 'une image',
          video: 'une vidéo',
          audio: 'un audio',
          resource: 'une pièce jointe',
        },
        the_type: {
          video: 'la vidéo',
          audio: 'l\'audio',
          resource: 'la pièce jointe',
        },
        embed_source: {
          video: 'Vimeo ou YouTube',
        },
        accept: {
          image: 'jpg, jpeg, gif, png',
          video: 'mp4',
          audio: 'mp3',
          resource: 'pdf, word, excel, csv, zip',
        },
        max: {
          image: '5 Mo max',
          video: '200 Mo max',
          audio: '200 Mo max',
          resource: '200 Mo max',
        },
      },
    },
  },
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['image', 'video', 'audio', 'resource'].includes(value);
      },
    },
    defaultSource: {
      type: String,
      default: null,
      validator(value) {
        return ['upload', 'url', 'embed'].includes(value);
      },
    },
    acceptedSources: {
      type: Array,
      default: () => ['upload', 'url', 'embed'],
    },
    storeUUID: {
      type: String,
      required: true,
    },
    contextId: {
      type: [String, Number],
      default: null,
    },
    contextType: {
      type: String,
      default: null,
    },
  },
  static: {
    accept: {
      image: 'image/*',
      video: 'video/mp4',
      audio: 'audio/mp3,audio/mpeg',
      resource: '',
    },
  },
  data() {
    return {
      media: null,
      mediaPath: '',
      mediaSource: this.defaultSource || this.acceptedSources[0],
      preview: null,
      isLoading: false,
      progressValue: null,
      progressTotal: null,
    };
  },
  computed: {
    canHaveManySources() {
      return (
        ['audio', 'video'].includes(this.type)
      );
    },
    canHaveUploadSource() {
      return this.acceptedSources.includes('upload');
    },
    canHaveEmbedSource() {
      return (
        this.acceptedSources.includes('embed')
        && ['video'].includes(this.type)
      );
    },
    mediaSize() {
      return Math.round((this.media.size / 1024 / 1024) * 100) / 100;
    },
  },
  methods: {
    onMediaUpload(file) {
      if (!file) {
        return;
      }

      const maxSize = {
        image: this.$constants.MAX_IMAGE_SIZE,
        video: this.$constants.MAX_GENERAL_MEDIA_SIZE,
        audio: this.$constants.MAX_GENERAL_MEDIA_SIZE,
        resource: this.$constants.MAX_GENERAL_MEDIA_SIZE,
      };

      if (file.size > maxSize[this.type]) {
        this.$buefy.dialog.alert(this.$t('messages.file_larger_than', {
          size: `${maxSize[this.type] / 1024 / 1024} Mo`,
        }));
        return;
      }

      this.media = file;

      if (this.type === 'image') {
        const loader = this.$buefy.loading.open({ container: this.$el });
        toBase64(file)
          .then((image) => (this.preview = image))
          .finally(() => loader.close());
      } else {
        this.onSubmit();
      }
    },
    deleteMedia() {
      this.media = null;
      this.preview = null;
    },
    formatUploadData(data) {
      const result = {
        id: data.id,
        type: this.type,
        source: this.mediaSource,
        size: data.size,
        url: data.url,
        mimetype: data.metadata.mime_type,
        options: {},
      };

      if (['image', 'video'].includes(this.type)) {
        result.width = data.metadata.width;
        result.height = data.metadata.height;
      }

      if (['audio', 'video'].includes(this.type)) {
        result.duration = data.metadata.duration;
        result.bitrate = data.metadata.bitrate;
      }

      return result;
    },
    onSubmit() {
      if (this.mediaSource !== 'upload') {
        this.media = null;
        this.$emit('done', {
          type: this.type,
          source: this.mediaSource,
          url: this.mediaPath,
          options: {},
        });
        return;
      }

      this.isLoading = true;
      APIUpload.upload({
        type: this.type,
        file: this.media,
        fileableId: this.contextId,
        fileableType: this.contextType,
      }, (progress) => {
        this.progressValue = progress.loaded;
        this.progressTotal = progress.total;
      })
        .then((data) => this.$emit('done', this.formatUploadData(data)))
        .finally(() => {
          this.progressValue = null;
          this.progressTotal = null;
          this.isLoading = false;
        });
    },
  },
};
</script>

<template>
  <AppCardModal
    class="qacfcomp is-relative"
    tag="form"
    @submit.prevent="onSubmit"
  >
    <template #title>
      Ajouter <span v-t="`a_type.${type}`" />
    </template>
    <template #body>
      <div class="has-text-centered">
        <slot />

        <div v-if="media">
          <figure v-if="preview" class="h-360">
            <img
              class="w-full h-full ofit-contain"
              :src="preview"
              :alt="media.name"
            >
          </figure>
          <p class="mt-3 break-words px-10">
            {{ media.name }} <br> {{ mediaSize }} Mo
          </p>
          <p class="mt-3">
            <b-button
              class="has-text-danger"
              type="is-text"
              @click="deleteMedia"
            >
              Supprimer
            </b-button>
          </p>
        </div>
        <div v-else>
          <b-field v-if="canHaveManySources" class="has-text-left">
            <template #label>
              Source de <span v-t="`the_type.${type}`" />
            </template>
            <b-select
              v-model="mediaSource"
              expanded
              required
              @input="mediaPath = ''"
            >
              <option
                v-if="canHaveUploadSource"
                value="upload">
                Téléverser
              </option>
              <option
                v-if="canHaveEmbedSource"
                value="embed"
                v-t="`embed_source.${type}`"
              />
              <option value="url">Lien direct (Amazon S3, serveur privé...)</option>
            </b-select>
          </b-field>

          <b-field
            v-if="mediaSource === 'url'"
            key="url"
            class="has-text-left"
          >
            <template #label>
              Entrer le lien direct de <span v-t="`the_type.${type}`" />
            </template>
            <b-input
              v-model="mediaPath"
              required
            />
          </b-field>
          <b-field
            v-else-if="mediaSource === 'embed'"
            key="embed"
            class="has-text-left"
          >
            <template #label>
              Entrer le lien <span v-t="`embed_source.${type}`" />
            </template>
            <b-input
              v-model="mediaPath"
              required
            />
          </b-field>
          <b-upload
            v-else
            class="is-block cursor-pointer"
            :accept="$options.static.accept[type]"
            native
            required
            @input="onMediaUpload"
          >
            <div class="border-dashed p-10 bradius-8 is-size-5">
              Cliquez pour ajouter <span v-t="`a_type.${type}`" />
              <br>
              (<span v-t="`accept.${type}`" />)
              <br>
              <p v-t="`max.${type}`" class="mt-2 has-text-dark is-size-6" />
            </div>
          </b-upload>

          <p
            v-if="mediaSource === 'url'"
            class="help is-success has-text-left"
          >
            Au format <span v-t="`accept.${type}`" />
          </p>
        </div>

        <b-loading :active="isLoading" :is-full-page="false">
          <div v-if="progressValue < progressTotal" class="w-full p-10">
            <b-progress
              :value="progressValue"
              :max="progressTotal"
            />
          </div>
        </b-loading>
      </div>
    </template>
    <template #foot>
      <b-button class="is-text" @click="$emit('cancel')">
        Annuler
      </b-button>
      <b-button type="is-primary" native-type="submit">
        Ajouter
      </b-button>
    </template>
  </AppCardModal>
</template>
