<script>
import debounce from 'lodash/debounce';
import APIGroup from '@shared/services/API/Community/Group';

export default {
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  form: {
  },
  data() {
    return {
      q: '',
      isLoading: false,
      availableMembers: {
        data: [],
      },
      newMember: {
        user: null,
        customer: null,
      },
    };
  },
  methods: {
    addMember(member) {
      member.is_loading = true;
      this.$store.dispatch('community/addMember', { member })
        .then(() => {
          member.is_member = true;
          this.$showMessage.success();
          this.$emit('add', member);
        })
        .finally(() => (member.is_loading = false));
    },
    search: debounce(function() {
      this.loadMembers();
    }, 500),
    loadMembers(url = null) {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      const q = this.q || undefined;
      APIGroup.getAvailableMembers(url || this.group.uuid, { q })
        .then(this.setMembers)
        .finally(() => (this.isLoading = false));
    },
    setMembers(data) {
      data.data = data.data.map((v) => ({
        ...v,
        is_member: false,
        is_loading: false,
      }));

      this.availableMembers = data;
    },
  },
  created() {
    this.loadMembers();
  },
};
</script>

<template>
  <AppCardModal
    class="is-relative"
    hasCloseBtn
    @close="$emit('cancel')"
  >
    <template #title>
      Ajouter un membre
    </template>
    <template #body>
      <GoProWrapper planLock="EXPERT" :hasSep="false">
        <div class="mh-360">
          <form @submit.prevent="search">
            <b-field label="Filtrez par prénom, nom ou email">
              <b-input
                v-model="q"
                expanded
                maxlength="32"
                @input="search"
              />
            </b-field>
          </form>

          <div class="is-relative pb-5">
            <ul v-if="availableMembers.data.length > 0" class="mt-5">
              <li
                v-for="member in availableMembers.data"
                :key="member.uuid"
                class="box flex-between-center"
              >
                <div>
                  <p>
                    {{ member.firstname }} {{ member.lastname }}
                  </p>
                  <p class="mt-2">
                    <span
                      class="tag"
                      :class="{
                        'is-dark': member.type === 'USER',
                        'is-primary': member.type === 'CUSTOMER',
                      }"
                      v-t="`community.member_type.${member.type}`"
                    />
                  </p>
                </div>
                <b-button
                  class="ml-5"
                  type="is-primary"
                  :disabled="member.is_member"
                  :loading="member.is_loading"
                  @click="addMember(member)"
                >
                  <template v-if="member.is_member">
                    Ajouté !
                  </template>
                  <template v-else>
                    Ajouter
                  </template>
                </b-button>
              </li>
            </ul>
            <p v-else>
              Il n'y a pas de résultat pour cette recherche.
            </p>
            <b-loading :active="isLoading" :is-full-page="false" />
          </div>

          <div
            v-if="availableMembers.meta && availableMembers.meta.last_page > 1"
            class="buttons is-centered mt-5 pb-5"
          >
            <b-button
              :disabled="!availableMembers.links.prev"
              @click="loadMembers(availableMembers.links.prev)"
            >
              Précédent
            </b-button>
            <b-button
              :disabled="!availableMembers.links.next"
              @click="loadMembers(availableMembers.links.next)"
            >
              Suivant
            </b-button>
          </div>
        </div>
      </GoProWrapper>
    </template>
    <template #foot>
      <b-button type="is-text" @click="$emit('cancel')">
        Fermer
      </b-button>
    </template>
  </AppCardModal>
</template>
