<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import utilsMixin from '@shared/mixins/utils';
import responsiveMixin from '@shared/mixins/responsive';
import communityMixin from '@shared/mixins/community';
import TiptapEditor from '@shared/components/TiptapEditor.vue';
import GenericUploadForm from '@shared/components/GenericUploadForm.vue';

export default {
  mixins: [
    utilsMixin,
    communityMixin('post'),
    responsiveMixin({ isMobile: 767 }),
  ],
  components: { TiptapEditor, GenericUploadForm },
  props: {
    group: {
      type: Object,
      required: true,
    },
    post: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      newPost: {
        title: '',
        content: '',
        media: {},
        media_type: null,
        published_at: null,
      },
      uploadType: null,
      uploadModalOpen: false,
      hasScheduleOpen: false,
      scheduleMinDate: new Date(),
      isLoading: false,
    };
  },
  validations: {
    newPost: {
      content: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters('community', [
      'communityStore',
    ]),
    hasTitleField() {
      return ['LIST', 'GRID'].includes(this.group.type);
    },
    acceptedSources() {
      if (['image', 'resource'].includes(this.uploadType)) {
        return ['upload'];
      }

      if (this.uploadType === 'video') {
        return ['embed', 'url'];
      }

      if (this.uploadType === 'audio') {
        return ['url'];
      }

      return null;
    },
  },
  methods: {
    ...mapMutations('community', [
      'closePostForm',
    ]),
    ...mapActions('community', [
      'updatePost',
      'addPost',
    ]),
    openScheduler() {
      if (!this.sharedHasExpertPlan) {
        this.$showMessage.goPro(() => {
          this.$router.push({ name: 'plan', query: { onglet: 'vous' } });
        }, null, { plan: 'EXPERT' });
        return;
      }

      this.hasScheduleOpen = true;
    },
    showUploadForm(type) {
      this.uploadType = type;
      this.uploadModalOpen = true;
    },
    onUpload(media) {
      const MEDIA_TYPE_MAP = {
        image: 'IMAGE',
        video: 'EMBED',
        resource: 'RESOURCE',
      };

      this.newPost.media = media;
      this.newPost.media_type = MEDIA_TYPE_MAP[this.uploadType];
      this.uploadType = null;
      this.uploadModalOpen = false;
    },
    removeMedia() {
      this.newPost.media = {};
      this.newPost.media_type = null;
    },
    handle() {
      const data = { ...this.newPost };
      data.published_at = this.$moment(data.published_at || undefined)
        .utc()
        .format(this.$constants.SERVER_DATE_FORMAT);

      data.media == null && (delete data.media);
      data.media_type == null && (delete data.media_type);
      this.hasTitleField === false && (delete data.title);

      this.isLoading = true;
      const promise = this.post
        ? this.updatePost({ uuid: this.post.uuid, data })
        : this.addPost({ uuid: this.group.uuid, data });

      promise
        .then(() => this.closePostForm())
        .finally(() => (this.isLoading = false));
    },
  },
  created() {
    this.hydrateData('newPost', 'post');

    if (this.post) {
      this.newPost.published_at = this.$moment
        .utc(this.newPost.published_at)
        .toDate();
    }
  },
};
</script>

<template>
  <AppCardModal
    tag="form"
    @submit.prevent="dataIsValid(isLoading) && handle()"
  >
    <template #title>
      <span>
        <template v-if="post">
          Modifier
        </template>
        <template v-else>
          Créer
        </template>
        <template v-if="group.type === 'LIST'">
          un sujet
        </template>
        <template v-else>
          une publication
        </template>
      </span>
    </template>
    <template #body>
      <b-field v-if="hasTitleField" label="Titre">
        <b-input
          v-model="newPost.title"
          maxlength="150"
          required
        />
      </b-field>
      <b-field v-bind="$getErrorProps($v.newPost.content, ['required'])">
        <TiptapEditor
          :hidden="['h2', 'table']"
          :height="120"
          placeholder="Quoi de neuf ?"
          autoFocus
          v-model="newPost.content"
        />
      </b-field>

      <div v-if="newPost.media.url" class="box flex-between-center">
        <a
          class="tdecoration-underline"
          :href="newPost.media.url"
          target="_blank"
          rel="noreferrer noopener"
        >
          Voir le média
          <template v-if="newPost.media.type === 'upload'">
            ({{ newPost.media.size | formatSizeToMB }} Mo)
          </template>
        </a>

        <b-button
          class="has-text-danger"
          type="is-text"
          @click="removeMedia"
        >
          Supprimer
        </b-button>
      </div>

      <teleport to="modals">
        <b-modal
          v-if="uploadModalOpen"
          :active="uploadModalOpen"
          :canCancel="false"
          :fullScreen="isMobile"
          hasModalCard
        >
          <GenericUploadForm
            :type="uploadType"
            :storeUUID="communityStore.uuid"
            :acceptedSources="acceptedSources"
            contextType="GROUP"
            :contextId="group.uuid"
            @cancel="uploadModalOpen = false"
            @done="onUpload"
          />
        </b-modal>
      </teleport>
    </template>
    <template #foot>
      <div class="level w-full">
        <div class="level-left">
          <div class="is-flex is-flex-wrap-wrap">
            <b-tooltip position="is-right" label="Ajouter une image">
              <b-button
                type="is-text"
                icon-left="image"
                @click="showUploadForm('image')"
              />
            </b-tooltip>
            <b-tooltip position="is-right" label="Ajouter une vidéo">
              <b-button
                type="is-text"
                icon-left="video"
                @click="showUploadForm('video')"
              />
            </b-tooltip>
            <b-tooltip position="is-right" label="Ajouter une pièce jointe">
              <b-button
                type="is-text"
                icon-left="file"
                @click="showUploadForm('resource')"
              />
            </b-tooltip>
            <b-field v-if="canManageCommunityEntity && !post" grouped>
              <p class="control">
                <b-tooltip
                  position="is-right"
                  label="Programmer pour plus tard"
                  :active="!hasScheduleOpen"
                >
                  <b-button
                    type="is-text"
                    icon-left="calendar"
                    @click="openScheduler"
                  />
                </b-tooltip>
              </p>
              <b-datetimepicker
                v-show="hasScheduleOpen"
                v-model="newPost.published_at"
                :min-datetime="scheduleMinDate"
                :datepicker="{ nearbyMonthDays: false }"
                :datetime-formatter="
                  (date) => $moment(date).format('DD/MM/YYYY, HH:mm')
                "
                position="is-top-right"
                close
                append-to-body
              />
            </b-field>
          </div>
        </div>
        <div class="level-right has-text-right">
          <nav>
            <b-button type="is-text" @click="$emit('cancel')">
              Annuler
            </b-button>
            <b-button
              type="is-primary is-custom"
              native-type="submit"
              :loading="isLoading"
            >
              <template v-if="post">
                Mettre à jour
              </template>
              <template v-else>
                Publier
              </template>
            </b-button>
          </nav>
        </div>
      </div>
    </template>
  </AppCardModal>
</template>
