<script>
import communityMixin from '@shared/mixins/community';

export default {
  mixins: [communityMixin('group')],
  props: {
    tag: {
      type: [String, Object],
      default: 'article',
    },
    store: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    hasBanner: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    bannerURL() {
      return (
        this.group.banner.url
        || `${this.$env.apiDomain}/img/banners/community-default.png`
      );
    },
    logoURL() {
      return (
        this.group.logo.url
        || this.store.logo
        || 'https://via.placeholder.com/96'
      );
    },
  },
};
</script>

<template>
  <component :is="tag" class="compgb box bradius-0-mobile p-0 is-clipped">
    <figure
      v-if="hasBanner"
      class="compgb_banner image"
      :class="{'is-editable': $slots.bannerActions && canManageCommunityEntity}"
    >
      <img
        class="ofit-cover oposition-top h-full"
        :src="bannerURL"
        :alt="`bannière de ${group.name}`"
      >
      <div v-if="$slots.bannerActions" class="image_actions buttons">
        <slot name="bannerActions" />
      </div>
    </figure>
    <div class="columns has-text-centered-mobile p-4">
      <div class="column is-narrow">
        <figure
          class="flex-none box p-1 mx-auto-mobile image is-96x96"
          :class="{'is-editable': $slots.logoActions && canManageCommunityEntity}"
        >
          <img
            class="ofit-cover oposition-top h-full bradius-4"
            :src="logoURL"
            :alt="`logo de ${group.name}`"
          >
          <div v-if="$slots.logoActions" class="image_actions buttons">
            <slot name="logoActions" />
          </div>
        </figure>
      </div>
      <div class="column">
        <h1 class="title is-7 mb-3">
          <b-tooltip
              v-if="group.state === 'PRIVATE' && canManageCommunityEntity"
              position="is-right"
          >
            <template #content>
              Cet espace est caché
            </template>
            <b-icon icon="user-lock" />
          </b-tooltip>
          <b-tooltip
              v-if="group.state === 'DRAFT'"
              position="is-right"
          >
            <template #content>
              Cet espace est en brouillon
            </template>
            <b-icon type="is-warning" icon="circle" />
          </b-tooltip>
          <b-tooltip
              v-if="!group.is_accessible"
              position="is-right"
          >
            <template #content>
              Vous n'avez pas accès à cet espace
            </template>
            <b-icon icon="lock" />
          </b-tooltip>
          {{ group.name }}
        </h1>

        <div class="columns">
          <div class="column">
            <p
              v-if="group.settings.show_members || canManageCommunityEntity"
              class="subtitle is-8 mb-1"
            >
              {{ group.members_count }}
              membre{{ group.members_count > 1 ? 's' : '' }}
            </p>
            <p
              v-if="canManageCommunityEntity"
              class="subtitle is-8 mb-1"
            >
              {{ group.posts_count }}
              {{ group.type === 'LIST' ? 'sujet' : 'publication' }}{{ group.posts_count > 1 ? 's' : '' }}
            </p>
          </div>

          <div class="column is-narrow">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<style lang="scss" scoped>
.compgb {
  &_banner {
    // 200px de hauteur, 728 de largeur
    padding-bottom: percentage(200 / 728);

    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
</style>
